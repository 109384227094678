import moment from "moment";
import { PxTalk } from "../types/bwic/PxTalk";
import { Currency } from "../types/enums/Currency";
import { LevelSpecificationType } from "../types/enums/LevelSpecificationType";
import { Rating } from "../types/enums/Rating";
import { arrayUtils } from './array.utils';
import { moneyUtils } from "./money.utils";
import { numericUtils } from "./numeric.utils";
import { levelSpecifications } from '../constants/level.specifications';
import { Color } from "../types/bwic/Color";
import { constants } from "../constants/constants";
import { BwicProcessType } from "../types/models/Process";
import { InventoryFloatingIndex, inventoryFloatingIndexTitles } from "../types/inventory/InventoryFloatingIndex";
import { CollateralType } from "../types/amr-pipeline/enums/CollateralType";
import { BidLevel } from "../types/bidding/BidLevel";
import { DealStatus } from "../types/amr-pipeline/enums/DealStatus";

export const formatUtils = {
    formatRatings,
    formatBoolean,
    formatBooleanWithPlaceholder,
    formatBwicTitle,
    formatNTimes,
    formatPxTalk,
    formatColor,
    formatBwicProcessTitle,
    formatBid,
    maskPhone,
    maskEmail,

    formatMargin,
    formatFactor,
    formatDecimal,
    formatInventoryCoupon,
    formatMonthAndYear,
    formatCollateralType,
    formatToBillions,

    formatUrlWithProtocol,

    formatBidLevel,
    formatDate,
    formatDealStatus,

    formatTickerRule144a
};

function formatDealStatus(status?: DealStatus) {
    return status === DealStatus.NewIssueDraft ? 'New Issue' : status;
}

function formatRatings(ratings?: Rating[]) {
    if (ratings && ratings.length) {
        const unique = [...new Set(ratings)];
        return unique.length === 1 ? unique[0] : 'Mixed List';
    }

    return '';
}

function formatBoolean(value?: boolean | null) {
    return value ? 'yes' : 'no';
}

function formatBooleanWithPlaceholder(value?: boolean | null) {
    return value ? 'Yes' : constants.emptyPlaceholder;
}

function formatBwicTitle(amount: number, currencies: Currency[], ratings: Rating[]) {
    const amountMM = moneyUtils.amountToMM(amount, true);
    const currency = [...new Set(currencies)].join('/');
    const rating = formatRatings(ratings);

    return `${currency.toUpperCase()} ${amountMM} ${rating}`;
}

function formatNTimes(times: number) {
    switch (+times) {
        case 1: return 'once';
        case 2: return 'twice';
        default: return `${times} times`;
    }
}

function formatPxTalk(pxTalks?: PxTalk[], defaultValue: string = "") {
    if (!pxTalks || !pxTalks.length) {
        return defaultValue;
    }

    const validPxTalks = pxTalks.filter(p => p.normalizedLevel != null && p.talk);

    if (!validPxTalks.length) return defaultValue;
    if (validPxTalks.length === 1) return validPxTalks[0].talk;

    const normalized = validPxTalks.map(p => Number(p.normalizedLevel));

    const from = Math.min(...normalized);
    const to = Math.max(...normalized);

    return from === to ? validPxTalks[0].talk : `${from}-${to}`;
}

function formatColorLevelSpecificationType(levelSpecificationType: LevelSpecificationType) {
    return !levelSpecificationType || levelSpecificationType === LevelSpecificationType.cover
        ? ''
        : levelSpecifications
            .toArray()
            .find(l => l.key === levelSpecificationType)
            ?.title;
}

function formatColor(color?: Color) {
    if (!color) {
        return '';
    }

    if (!color.level) {
        return color.traded ? 'Traded' : 'DNT';
    }

    const traded = color.traded ? '' : 'DNT';
    const levelSpecification = formatColorLevelSpecificationType(color.levelSpecificationType);
    const level = color.level ? color.level : '';
    return [traded, levelSpecification, level].join(' ').trim();
}

function formatBwicProcessTitle(type: BwicProcessType) {
    switch (type) {
        case BwicProcessType.Standard: return "Standard";
        case BwicProcessType.Live: return "LiveBidding™";
        case BwicProcessType.JumpBall: return "Jump Ball";
        case BwicProcessType.TopX: return "Top X";
        case BwicProcessType.BestFootForward: return "Best Foot Forward";
        case BwicProcessType.Unknown: return "Other";
        default: return "";
    }
}

function formatBid(bid?: number, defaultValue: string = '') {
    if (bid == null || !numericUtils.isNumber(bid)) return defaultValue;

    return bid != null && numericUtils.isNumber(bid)
        ? String(formatDecimal(bid, 4))
        : defaultValue;
}

function maskPhone(phone: string, numberOfVisibleSymbols = 2) {
    if (!phone) {
        return phone;
    }

    let count = 0
    let numberOfDigits = phone.replace(/[^0-9]/g, "").length;

    const masked = [...phone].map(s => {
        if (numericUtils.isNumber(s)) {
            count++;

            if (count > numberOfVisibleSymbols && count <= (numberOfDigits - numberOfVisibleSymbols)) {
                return '*';
            }
        }

        return s;
    });

    return masked.join('');
}

function maskEmail(email?: string, numberOfVisibleSymbols = 2, numberOfHiddenSymbols = 6) {
    if (!email) {
        return email;
    }

    const [part1, part2] = email.split('@');

    if (!part1 || !part2) {
        return email;
    }

    let part1Encoded = part1.substring(0, numberOfVisibleSymbols) + arrayUtils.repeat('*', numberOfHiddenSymbols).join('');
    return `${part1Encoded}@${part2}`;
}

function formatMargin(margin?: number) {
    return formatDecimal(margin, 3);
}

function formatFactor(factor?: number) {
    return formatDecimal(factor, 4);
}

function formatDecimal(value?: number, decimalPlaces = 2) {
    if (value == null || !numericUtils.isNumber(value)) return value;

    const numericValue = Number(value);

    return numericUtils.round(numericValue, decimalPlaces).toFixed(decimalPlaces);
}

function formatInventoryCoupon(inventoryPosition: { floaterIndex?: InventoryFloatingIndex; spread?: number; }, emptyValue = constants.emptyPlaceholder) {
    const floaterIndex =
        inventoryPosition &&
        inventoryPosition.floaterIndex &&
        inventoryFloatingIndexTitles[inventoryPosition.floaterIndex];
    const spread =
        inventoryPosition &&
        inventoryPosition.spread &&
        numericUtils.isNumber(inventoryPosition.spread) &&
        formatDecimal(inventoryPosition.spread);

    if (floaterIndex && spread) {
        return `${floaterIndex}+${spread}%`;
    } else if (spread) {
        return `${spread}%`;
    } else if (floaterIndex) {
        return `${floaterIndex}+[]%`;
    }

    return emptyValue;
}

// Adds additional zero for month
// value: MM/YY
function formatMonthAndYear(monthYearStamp?: string, defaultValue?: string) {
    if (monthYearStamp) {
        const [month, year] = String(monthYearStamp).split('/');
        if (numericUtils.isNumber(month) && numericUtils.isNumber(year)) {
            return `${String(month).padStart(2, '0')}/${String(year).padStart(2, '0')}`
        }
    }

    return defaultValue;
}

function formatCollateralType(collateralType?: CollateralType) {
    switch (collateralType) {
        case CollateralType.broadlySyndicated:
            return 'BSL';
        case CollateralType.middleMarket:
            return 'MM';
        default:
            return constants.emptyPlaceholder;
    }
}

function formatToBillions(value: number, precision = 2, withDecimals = false) {
    if (!numericUtils.isNumber(value)) {
        return undefined;
    }

    const billions = numericUtils.round(value / Math.pow(10, 9), precision);

    return `${withDecimals ? billions.toFixed(precision) : billions}B`;
}

function formatUrlWithProtocol(hostname?: string) {
    if (!hostname) {
        return hostname;
    }

    return new RegExp('https?://').test(hostname)
        ? hostname
        : `http://${hostname}`;
}

function formatBidLevel(level: BidLevel | undefined, hasManySameLevelBids = false, improversCount: number) {
    const isFifthSupported = improversCount === 5;
    const isFourthSupported = improversCount === 4 || isFifthSupported;
    const isThirdSupported = improversCount === 3 || isFourthSupported;

    const notInTop = improversCount ? `Not in top ${improversCount}.` : '';

    switch (level) {
        case BidLevel.Best: return hasManySameLevelBids ? 'Tied for the best level' : 'Best';
        case BidLevel.Cover: return 'Cover';
        case BidLevel.Third: return isThirdSupported ? 'Third' : notInTop;
        case BidLevel.Fourth: return isFourthSupported ? 'Fourth' : notInTop;
        case BidLevel.Fifth: return isFifthSupported ? 'Fifth' : notInTop;
        default: return notInTop;
    }
}

function formatDate(date: Date | undefined, format: string = constants.dateFormat) {
    return date ? moment(date).format(format) : constants.emptyPlaceholder;
};

function formatTickerRule144a(dealTicker: string, className: string, rating: Rating) {
    if (!dealTicker || !className || rating === Rating.NR) return '';

    const wordsToReplace = [
        'notes', 'note', 'fxd', 'fixed', 'fix', 'flt', 'snr',
        'senior', 'floating', 'float', 'flaoting', 'foating', 'junior', 'jnr', 'bond'
    ];

    const sanitizedClassName = className
        .replace(/[-() //]/g, '')
        .replace(/loans|loan/ig, 'l')
        .replace(new RegExp(wordsToReplace.map(w => w.toUpperCase()).join('|'), 'gi'), '')

    if (!sanitizedClassName) return '';

    return `${dealTicker.toUpperCase()} ${sanitizedClassName.toUpperCase()}`;
}
