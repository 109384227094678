import { castArray, get, keyBy, mapValues, sumBy } from 'lodash';
import moment from 'moment';
import { user } from "../user/user";
import { constants, pipelineFilters, roles, routes, SORT } from "../constants";
import { dateRangeFilterOptions, dateTimeRangeFilterOptions } from '../constants/date-range.filter';
import { DateFilterOption } from '../types/filters/DateFilterOption';
import { DateTimeFilterOption } from '../types/filters/DateTimeFilterOption';
import { TimeOption } from '../types/filters/TimeOption';
import { IOI } from '../types/amr-pipeline/models/IOI';
import { Contact } from '../types/amr-pipeline/models/Contact';
import { CollateralQualityTest } from '../types/amr-pipeline/models/CollateralQualityTest';
import { compareFullNames, compareNumbersWithNullAcs, compareStrings } from './compare.utils';
import { OriginatingTransactionClass } from '../types/amr-pipeline/models/OriginatingTransactionClass';
import { OriginatingTransactionClassStatus } from '../types/amr-pipeline/enums/OriginatingTransactionClassStatus';
import { OriginatingTransactionDocument } from '../types/amr-pipeline/models/OriginatingTransactionDocument';
import { Company } from '../types/amr-pipeline/models/Company';
import { Transaction } from '../types/amr-pipeline/models/Transaction';
import { PipelineType } from '../types/amr-pipeline/enums/PipelineType';
import {
    FilterSelectGroup,
    FilterRangeGroup,
    FilterDateGroup,
    FilterDateTimeGroup,
    FilterBooleanGroup,
} from '../types/filters/FilterGroup';
import { DealsPipelineFilters } from '../types/state/PipelineFilterState';
import dateTimeUtils from './dateTime.utils';
import { numericUtils } from './numeric.utils';
import { DealClassSearchData } from '../components/amrPipeline/types/DealClassSearchItem';
import { SearchTermItem } from '../components/amrPipeline/types/SearchTermItem';
import { ClassIndicators } from '../types/amr-pipeline/models/ClassIndicators';
import { TransactionStatus } from '../types/amr-pipeline/enums/TransactionStatus';
import { UserConfigFilter } from '../types/user-config/UserConfigFilter';
import { UserConfigColumn } from '../types/user-config/UserConfigColumn';
import { UserConfigType } from '../types/user-config/UserConfigType';
import { CompanyAlertsValue, ImUserConfig, SecondaryStatsAlertsValue } from '../types/user-config/UserConfig';
import { OriginatingTransaction } from '../types/amr-pipeline/models/OriginatingTransaction';
import { AmrTransaction } from '../types/amr-pipeline/models/AmrTransaction';
import { TransactionType } from '../types/amr-pipeline/enums/TransactionType';
import { PipelineDetailedTabTypes } from '../components/amrPipeline/types/PipelineDetailedTabTypes';
import { TransactionsTabTypes } from '../components/amrPipeline/types/TransactionsTabTypes';
import { selectedRadioOption } from './amr-pipeline-filter.utils';
import { imSubscriptionTitle, IssuanceMonitorSubscriptionType } from '../types/amr-pipeline/enums/IssuanceMonitorSubscriptionType';
import { DownloadedDocumentAccessType } from '../types/amr-pipeline/enums/DownloadedDocumentAccessType';
import { TransactionAccessType } from '../types/amr-pipeline/enums/TransactionAccessType';
import { AnalyticsSession, ViewedEntity } from '../types/amr-pipeline/models/AnalyticsSession';
import { TransactionViewHistoryClientsActivity } from '../types/amr-pipeline/models/TransactionViewHistoryClientsActivity';
import { SubscriptionFeature } from '../types/billing/SubscriptionFeature';
import { AlertOption } from '../types/email-preferences/EmailPreferences';
import { WithReferenceName } from '../types/amr-pipeline/models/WithReferenceName';
import { Bank, BwicBank } from '../types/banks/Bank';
import { ContactType } from '../types/amr-pipeline/enums/ContactType';
import { Deal } from '../types/amr-pipeline/models/Deal';
import { AmrFile } from '../types/amr-pipeline/models/AmrFile';
import { Document } from '../types/document/Document';

export function canViewTransactionDetails() {
    return user.hasRoles(
        ...roles.issuanceMonitorAccess(),
        roles.SubscriptionManager,
    );
}

function selectedSelectGroupOptions(filter: FilterSelectGroup, fallback?: any) {
    const selectedOptions = filter?.filter.filter(m => m.selected).map(m => m.value);

    if (!selectedOptions.length) {
        return fallback;
    }

    return selectedOptions;
}

function selectedRangeOptions(filter: FilterRangeGroup) {
    const { from, to } = filter?.filter || {};
    return {
        from: numericUtils.isNumber(from) ? Number(from) : undefined,
        to: numericUtils.isNumber(to) ? Number(to) : undefined,
    };
}

function selectedDateOptions(filter?: FilterDateGroup) {
    const { from, to } = filter?.filter.options.customDateRange || {};
    return {
        from: from ? moment(from).startOf('day').format(constants.dateFormatISO8601) : undefined,
        to: to ? moment(to).endOf('day').format(constants.dateFormatISO8601) : undefined,
    };
}

function selectedDateWithTimeOptions(filter?: FilterDateTimeGroup) {
    const { from, to } = filter?.filter.selectedOption || {};

    return {
        from: from ? dateTimeUtils.changeDateTimeZone(from.date, constants.estTimezone).format() : undefined,
        to: to ? dateTimeUtils.changeDateTimeZone(to.date, constants.estTimezone).format() : undefined,
    };
}

function selectedBooleanOption(filter?: FilterBooleanGroup) {
    const selectedOption = filter?.filter.selectedOption;
    return selectedOption !== null ? selectedOption : undefined;
}

function selectedDaysOptions(filter: FilterDateGroup) {
    const { from, to } = filter?.filter.options.customYearsRange || {};
    const daysOptions = {
        from: numericUtils.isNumber(from) ? numericUtils.floor(Number(from) * 365, 0) : undefined,
        to: numericUtils.isNumber(to) ? numericUtils.round(Number(to) * 365, 0) : undefined,
    };

    if (daysOptions.from && !daysOptions.to) {
        daysOptions.to = numericUtils.round(Number(pipelineFilters.maxRangeValue) * 365, 0);
    }

    return daysOptions;
}

export function getFilterCriteria(filter: DealsPipelineFilters, pipelineType: PipelineType) {
    return {
        statuses: selectedSelectGroupOptions(filter.statuses, constants.defaultTransactionsSearchCriteria.statuses),
        types: selectedSelectGroupOptions(filter.transactionTypes, constants.defaultTransactionsSearchCriteria.types),
        ratings: selectedSelectGroupOptions(filter.ratings),
        collateralManagers: selectedSelectGroupOptions(filter.managers),
        arrangers: selectedSelectGroupOptions(filter.arrangers),
        collateralTypes: selectedSelectGroupOptions(filter.collateralType),
        amrDeal: selectedBooleanOption(filter.amrDeal),
        currencyCodes: selectedSelectGroupOptions(filter.currency),
        euCompliance: selectedBooleanOption(filter.euCompliance),
        esg: selectedBooleanOption(filter.esg),
        sofr: selectedBooleanOption(filter.sofr),
        isDebut: selectedBooleanOption(filter.isDebut),
        // enhancedCLO: selectedBooleanOption(filter.enhancedCLO), Temporary Enhanced CLO hiding
        staticDeal: selectedBooleanOption(filter.staticDeal),
        classNames: selectedSelectGroupOptions(filter.classNames),
        warf: selectedRangeOptions(filter.warf),
        was: selectedRangeOptions(filter.was),
        ds: selectedRangeOptions(filter.ds),
        closingDate: selectedDateOptions(filter.closingDate),
        pricingDate: selectedDateOptions(filter.pricingDate),
        nonCallPeriodEnd: selectedDateOptions(filter.nonCallEnd),
        reinvestmentPeriodEnd: selectedDateOptions(filter.reinvestmentEnd),
        nonCallPeriodEndDays: selectedDaysOptions(filter.nonCallEnd),
        reinvestmentPeriodEndDays: selectedDaysOptions(filter.reinvestmentEnd),
        parSubordination: selectedRangeOptions(filter.parSubordination),
        mvoc: selectedRangeOptions(filter.mvoc),
        rollerDeadline: selectedDateWithTimeOptions(filter.rollerDeadline),
        hasIois: pipelineType === PipelineType.IOIs || null,
        includeIois: pipelineType === PipelineType.IOIs,
        trustees: selectedSelectGroupOptions(filter.trustees),
        vintage: selectedDateOptions(filter.vintage),
        coupons: selectedRadioOption(filter.coupons),
        outOfNC: selectedBooleanOption(filter.outOfNC),
        outOfRI: selectedBooleanOption(filter.outOfRI),
    };
}

export function isBrokerDealersOwnTransaction(transaction?: Transaction, userCompany?: Company) {
    const isBrokerDealer = user.hasRoles(...roles.bd());
    const isOwnTransaction = transaction?.arranger?.referenceName === userCompany?.referenceName;

    return isBrokerDealer && isOwnTransaction;
}

export function isCollateralManagersOwnTransaction(transaction?: Transaction, userCompany?: Company) {
    const isCollateralManager = user.hasRoles(roles.CollateralManager);
    const isOwnTransaction = transaction?.collateralManager?.referenceName === userCompany?.referenceName
        || transaction?.collateralManagerOnDealReferenceName === userCompany?.referenceName

    return isCollateralManager && isOwnTransaction;
}

export function hasLimitedAccess(transaction?: Transaction, userCompany?: Company) {
    const isBrokerDealer = user.hasRoles(...roles.bd());

    if (isBrokerDealer) {
        return transaction?.arranger?.referenceName !== userCompany?.referenceName;
    }

    const isSingleRoleCollateralManager = user.hasSingleRole(roles.CollateralManager);

    if (isSingleRoleCollateralManager) {
        return (
            (transaction?.collateralManager?.referenceName !== userCompany?.referenceName &&
                transaction?.collateralManagerOnDealReferenceName !== userCompany?.referenceName) ||
            transactionHasArrangerProSubscription(transaction)
        );
    }

    return false;
}

export function hasAnalyticsAccess(transaction?: Transaction, userCompany?: Company) {
    if (transaction?.status === TransactionStatus.Draft) {
        return false
    }

    const isAdmin = user.hasRoles(roles.Administrator);
    const brokerDealersOwnTransaction = isBrokerDealersOwnTransaction(transaction, userCompany);
    const collateralManagersOwnTransaction = isCollateralManagersOwnTransaction(transaction, userCompany);

    return isAdmin || brokerDealersOwnTransaction || collateralManagersOwnTransaction;
}

export function hasAnalyticsAlertsAccess(transaction?: Transaction, userCompany?: Company) {
    if(transaction?.status !== TransactionStatus.Active) {
        return false;
    }

    return hasAnalyticsAccess(transaction, userCompany);
}

export function hasInvitedClientsAccess(transaction?: Transaction, userCompany?: Company) {
    if (transaction?.status === TransactionStatus.Draft) {
        return false
    }

    const isAdmin = user.hasRoles(roles.Administrator);

    return isAdmin || isBrokerDealersOwnTransaction(transaction, userCompany);
}

export function hasIOIsAccess(transaction?: Transaction, userCompany?: Company) {
    if (!transaction) {
        return user.hasRoles(...roles.bd(), ...roles.seller(), roles.CollateralManager);
    }

    const brokerDealersOwnTransaction = isBrokerDealersOwnTransaction(transaction, userCompany);
    const collateralManagersOwnTransaction = isCollateralManagersOwnTransaction(transaction, userCompany);
    const isSeller = user.hasRoles(roles.SellerTrader, roles.SellerViewer);

    const isInvitedClient = user.hasSingleRole(roles.ArrangersClient);

    return isSeller || brokerDealersOwnTransaction || collateralManagersOwnTransaction || isInvitedClient;
}

export function getTransactionDetailsUrl(
    transaction: OriginatingTransaction | AmrTransaction,
    activeTab?: PipelineDetailedTabTypes,
) {
    const isAmr = transaction.type === TransactionType.amr;

    const defaultTab = isAmr ? undefined : TransactionsTabTypes.overview;

    return routes.transactionDetailUrl(
        transaction.referenceName,
        transaction.dealReferenceName,
        activeTab === PipelineDetailedTabTypes.ioi ? TransactionsTabTypes.IOIs : defaultTab,
    );
}

export function canViewIOIs(transaction?: Transaction, userCompany?: Company) {
    if (!transaction || !userCompany) {
        return false;
    }

    const { type, status } = transaction;

    if (type === TransactionType.amr) {
        return false;
    }

    const brokerDealersOwnTransaction = isBrokerDealersOwnTransaction(transaction, userCompany);
    const collateralManagersOwnTransaction = isCollateralManagersOwnTransaction(transaction, userCompany);

    const activeStatus = status === TransactionStatus.Active;
    const pricedClosedOnHoldStatus = [
        TransactionStatus.Priced,
        TransactionStatus.Closed,
        TransactionStatus.OnHold
    ].includes(status);

    const isSellerTrader = user.hasRoles(roles.SellerTrader);
    const isSellerViewer = user.hasRoles(roles.SellerViewer);
    const isSeller = isSellerTrader || isSellerViewer;

    if (isSellerViewer && activeStatus) {
        return true;
    }

    if (isSeller && pricedClosedOnHoldStatus) {
        return true;
    }

    if ((brokerDealersOwnTransaction || collateralManagersOwnTransaction) && (activeStatus || pricedClosedOnHoldStatus)) {
        return true;
    }

    return false;
}

export function canSubmitIOIs(transaction?: Transaction) {
    if (!transaction) {
        return false;
    }

    const { type, status } = transaction;

    if (type === TransactionType.amr) {
        return false;
    }

    const activeStatus = status === TransactionStatus.Active;
    const isSellerTrader = user.hasRoles(roles.SellerTrader);

    return isSellerTrader && activeStatus;
}

export function hasWidgetsAccess(...features: SubscriptionFeature[]) {
    return user.hasRoles(...roles.issuanceMonitorAccess()) && (!features?.length || user.hasFeatures(...features));
}

export function hasKWatchNewsAccess() {
    return user.hasRoles(...roles.bd(), ...roles.seller(), roles.DataEntry, roles.Administrator);
}

export function isCollateralManagersOwnCompany(company?: WithReferenceName, userCompany?: Company) {
    const isCollateralManager = user.hasRoles(roles.CollateralManager);
    return isCollateralManager && company?.referenceName === userCompany?.referenceName;
}

export function isBrokerDealersOwnCompany(company?: WithReferenceName, userCompany?: Company) {
    const isBrokerDealer = user.hasRoles(...roles.bd());
    return isBrokerDealer && company?.referenceName === userCompany?.referenceName;
}

export function canEditCloManager(company?: WithReferenceName, userCompany?: Company) {
    const collateralManagersOwnCompany = isCollateralManagersOwnCompany(company, userCompany);
    return user.hasRoles(roles.Administrator, roles.DataEntry) || collateralManagersOwnCompany;
}

export function canEditBank(company?: WithReferenceName, userCompany?: Company) {
    const brokerDealersOwnCompany = isBrokerDealersOwnCompany(company, userCompany);
    return user.hasRoles(roles.Administrator, roles.DataEntry) || brokerDealersOwnCompany;
}

export function hasCloManagerAnalyticsAccess(isUserCompany: boolean) {
    if (user.hasRoles(roles.Administrator, roles.DataEntry)) {
        return true;
    }

    const collateralManager = user.hasRoles(roles.CollateralManager);
    const sellerViewer = user.hasRoles(roles.SellerViewer);
    const sellerTrader = user.hasRoles(roles.SellerTrader);
    const sellerAdmin = user.hasRoles(roles.SellerAdministrator);

    const isCMAndViewerOrSeller = collateralManager && (sellerViewer || sellerTrader);

    return isUserCompany && (isCMAndViewerOrSeller || (isCMAndViewerOrSeller && sellerAdmin));
}

export function hasBankAnalyticsAccess(bank?: WithReferenceName, userCompany?: Company) {
    if (user.hasRoles(roles.Administrator, roles.DataEntry)) {
        return true;
    }

    return isBrokerDealersOwnCompany(bank, userCompany);
}

export function hasBankAnalyticsAlertsAccess(banks?: WithReferenceName | WithReferenceName[], userCompany?: Company) {
    return castArray(banks).some(bank => isBrokerDealersOwnCompany(bank, userCompany));
}

export function hasBankAnalyticsAlertsChangeAccess(banks?: WithReferenceName | WithReferenceName[], userCompany?: Company) {
    return (
        user.hasFeatures(SubscriptionFeature.DealerProfileEmailAlerts) &&
        hasBankAnalyticsAlertsAccess(banks, userCompany)
    );
}

export function transactionHasArrangerProSubscription(transaction?: Transaction) {
    return transaction?.arranger?.subscription === imSubscriptionTitle[IssuanceMonitorSubscriptionType.Pro];
}

export function hasArrangerAccess(transaction?: Transaction) {
    return transactionHasArrangerProSubscription(transaction) || user.hasSingleRole(roles.ArrangersClient);
}

export function getRangeFromDateOption(dateRangeFilterOption: DateFilterOption) {
    const today = moment();
    const lastBusinessDay = dateTimeUtils.getLastBusinessDay();

    const monday = moment().isoWeekday(1);
    const friday = moment().isoWeekday(5);

    const weekAgoStart = moment().subtract(1, "week");
    const startOfMonth = moment().startOf("month");
    const monthAgo = moment().subtract(1, "month");
    const threeMonthAgo = moment().subtract(3, "month");
    const sixMonthAgo = moment().subtract(6, "month");
    const startYear = moment().startOf('year');
    const endYear = moment().endOf('year');

    switch (dateRangeFilterOption.key) {
        case (dateRangeFilterOptions.Today.key):
            return {
                from: moment().startOf("day"),
                to: moment().endOf("day"),
            };
        case (dateRangeFilterOptions.TodayAndUpcoming.key):
            return {
                from: moment().startOf("day")
            };
        case (dateRangeFilterOptions.ThisWeek.key):
            return {
                from: monday,
                to: today,
            };
        case (dateRangeFilterOptions.ThisWeekMondayToFriday.key):
            return {
                from: monday,
                to: friday
            };
        case (dateRangeFilterOptions.LastWeek.key):
            return {
                from: weekAgoStart,
                to: today,
            }
        case (dateRangeFilterOptions.ThisMonth.key):
            return {
                from: startOfMonth,
                to: today
            }
        case (dateRangeFilterOptions.LastMonth.key):
            return {
                from: monthAgo,
                to: today
            }
        case (dateRangeFilterOptions.LastThreeMonth.key):
            return {
                from: threeMonthAgo,
                to: today
            }
        case (dateRangeFilterOptions.LastSixMonth.key):
            return {
                from: sixMonthAgo,
                to: today
            }
        case (dateRangeFilterOptions.ThisYear.key):
            return {
                from: startYear,
                to: endYear
            }
        case (dateRangeFilterOptions.LastYear.key):
            return {
                from: moment().subtract(1, 'year').startOf('day'),
                to: moment().endOf('day')
            }
        case (dateRangeFilterOptions.Yesterday.key):
            return {
                from: lastBusinessDay,
                to: lastBusinessDay.endOf('day')
            }
        default:
            return undefined;
    }
}

export function getRangeFromDateTimeOption(dateRangeFilterOption: DateTimeFilterOption) {
    switch (dateRangeFilterOption.key) {
        case (dateTimeRangeFilterOptions.Today.key):
            return {
                from: {
                    date: moment().startOf("day"),
                    timeOption: TimeOption.AnyTime,
                },
                to: {
                    date: moment().endOf("day"),
                    timeOption: TimeOption.AnyTime,
                },
            };
        default:
            return undefined;
    }
}

function createIoIRating(i: IOI) {
    const ioiRatings = `${i.ratingMoodys || constants.emptyPlaceholder}/${i.ratingSnP || constants.emptyPlaceholder}/${i.ratingFitch || constants.emptyPlaceholder
        }/${i.ratingKbra || constants.emptyPlaceholder}/${i.ratingDbrs || constants.emptyPlaceholder}`;

    return {
        ...i,
        ioiRatings
    }
}

function calculateIoISizePercent(i: IOI) {
    return {
        ...i,
        sizePercent: i.size * 100 / i.balance
    }
}

function indicateRefinancedIoIs(iois: IOI[], classBalance: number) {
    const ioisTotalBalance = sumBy(iois, (ioi) => ioi.size);

    let ioisTotalSize = 0;

    return iois
        .sort((a, b) => compareNumbersWithNullAcs(a.dm, b.dm))
        .map((ioi) => {
            const isRefinanced = ioisTotalBalance < classBalance ? false : ioisTotalSize < classBalance;
            const sizePercent = ioi.size * 100 / classBalance;

            ioisTotalSize = ioisTotalSize + ioi.size;

            return { ...ioi, sizePercent, isRefinanced };
        });
}

export function withIndicatingRefinancedIoIs(classes: OriginatingTransactionClass[]) {
    return classes.reduce((acc: OriginatingTransactionClass[], tc) => {
        if (!tc.ioIs || !tc.ioIs.length) {
            return acc;
        }

        return [...acc, { ...tc, ioIs: indicateRefinancedIoIs(tc.ioIs, tc.balance || 0) }];
    }, []);
}

export function formatIoI(i: IOI) {
    return calculateIoISizePercent(createIoIRating(i));
}

export function isIoIonDisabledClass(ioi: IOI, classes: OriginatingTransactionClass[]) {
    const ioiClass = classes.find(c => c.referenceName === ioi.classReferenceName);

    return (
        ioiClass?.originatingTransactionClassStatus === OriginatingTransactionClassStatus.Subject ||
        ioiClass?.originatingTransactionClassStatus === OriginatingTransactionClassStatus.NotOffered
    );
}

export function sortContactsByPrimary(members: Contact[], primaryContact?: Contact) {
    return members
        .map(member => ({
            ...member,
            isPrimary: member.email === primaryContact?.email,
        }))
        .sort((a, b) => +b.isPrimary - +a.isPrimary);
}

export function sortCollateralQualityTests(collateralQualityTests?: CollateralQualityTest[]) {
    if (!collateralQualityTests) {
        return [];
    }

    return collateralQualityTests.sort((a: CollateralQualityTest, b: CollateralQualityTest) =>
        compareStrings(a.value, b.value)
    );
}

export function sortSyndicateContacts<T extends Contact>(
    syndicateContacts?: T[],
    defaultSortFn: (a: T, b: T) => number = compareFullNames)
{
    if (!syndicateContacts) {
        return syndicateContacts;
    }

    const isOfType = (type: ContactType) => (contact: T) => contact.type === type;
    const isDistributionList = isOfType(ContactType.DistributionList);
    const isHeadOfDesk = isOfType(ContactType.HeadOfDesk);

    return syndicateContacts.sort((a: T, b: T) => {
        const byDistributionList = +isDistributionList(b) - +isDistributionList(a);
        const byHeadOfTrading = +(b.headOfTrading || 0) - +(a.headOfTrading || 0);
        const byHeadOfDesk = +isHeadOfDesk(b) - +isHeadOfDesk(a);

        if (byDistributionList) {
            return byDistributionList;
        } else if (byHeadOfDesk) {
            return byHeadOfDesk;
        } else if (byHeadOfTrading) {
            return byHeadOfTrading;
        }

        return defaultSortFn(a, b);
    });
}

export const generateIntexLink = (positionId: string) => `INTEXcalc://?position=${encodeURIComponent(positionId)}`;

const getCurrentIntexPositionByIdentifiers = (tranches: ClassIndicators[]) => {
    const identifierHierarchy: (keyof ClassIndicators)[] = [
        'ticker144A',
        'cusiP144A',
        'isiN144A',
        'tickerRegS',
        'cusipRegS',
        'isinRegS',
        'tickerAccdInvCertif',
        'cusipAccdInvCertif',
        'isinAccdInvCertif'
    ];

    let identifierForIntex;

    for (let i = 0; i < identifierHierarchy.length; i++) {
        const hierarchyKey = identifierHierarchy[i];

        const trancheWithIdentifier = tranches.find(tranche => !!tranche[hierarchyKey]);

        if (trancheWithIdentifier) {
            identifierForIntex = trancheWithIdentifier[hierarchyKey];
            break;
        }
    }

    return identifierForIntex;
}

export const getCurrentIntexPositionIdForTransaction = (transaction: OriginatingTransaction | AmrTransaction) => {
    if (transaction.dealTicker) {
        return transaction.dealTicker;
    }

    const tranches = (transaction as Transaction).type === TransactionType.amr
        ? (transaction as AmrTransaction).classes.map(x => x.class)
        : (transaction as OriginatingTransaction).classes
            .reduce(
                (acc: ClassIndicators[], transactionClass: OriginatingTransactionClass) => transactionClass.tranche
                    ? [...acc, transactionClass.tranche]
                    : acc
                ,[]);

    return getCurrentIntexPositionByIdentifiers(tranches);
}

export const getCurrentIntexPositionIdForDeal = (deal: Deal) => {
    if (deal.ticker) {
        return deal.ticker;
    }

    return getCurrentIntexPositionByIdentifiers(deal.classes);
}

export function getSearchedTransactionDocuments(documents: OriginatingTransactionDocument[], searchTerm: string) {
    const searchTermLowerCase = searchTerm.toLowerCase();
    return documents.filter((d) => {
        const date = d.executionDate ?? d.uploadTime;
        const dateText = moment(date).format(constants.dateFormat);
        return d.name.toLowerCase().includes(searchTermLowerCase) || dateText.toLowerCase().includes(searchTermLowerCase);
    });
}

export function groupSearchByDealAndClassIndicators(searchTermItems: SearchTermItem[]) {
    return searchTermItems.reduce((accum: DealClassSearchData, { referenceName, classNames }) => {
        const { deals, dealsClasses } = accum;
        const dealClassIndex = dealsClasses.map(el => el.dealReferenceName).indexOf(referenceName);
        const isDealPresent = deals.includes(referenceName);
        const isDealClassPresent = dealClassIndex > -1;

        if (classNames && !isDealPresent) {
            return {
                ...accum,
                dealsClasses: isDealClassPresent
                    ? [
                        ...dealsClasses.slice(0, dealClassIndex),
                        {
                            dealReferenceName: referenceName,
                            classNames: [
                                ...classNames,
                                ...dealsClasses[dealClassIndex].classNames
                            ]
                        },
                        ...dealsClasses.slice(dealClassIndex + 1)
                    ] : [
                        ...dealsClasses,
                        { dealReferenceName: referenceName, classNames }
                    ]
            }
        } else {
            return {
                deals: [...accum.deals, ...(!isDealPresent ? [referenceName] : [])],
                dealsClasses: isDealClassPresent
                    ? dealsClasses.filter((c, index) => index !== dealClassIndex)
                    : dealsClasses,
            }
        }
    }, { deals: [], dealsClasses: [] })
}

export function withSearchedClassIndicator(searchTerm: string, tranche?: ClassIndicators) {
    if (!tranche) {
        return false;
    }

    const allIndicators: (string | undefined)[] = [
        tranche.ticker144A,
        tranche.tickerRegS,
        tranche.tickerAccdInvCertif,
        tranche.cusiP144A,
        tranche.cusipRegS,
        tranche.cusipAccdInvCertif,
        tranche.isiN144A,
        tranche.isinRegS,
        tranche.isinAccdInvCertif
    ];

    return allIndicators.some(i => i?.toLowerCase().includes(searchTerm.toLowerCase()))
}

export function mergeSortCriteria(sortBy: string, sortDirection: string, defaultCriteria: { field: string }[]) {
    const additionalCriteria = defaultCriteria.some(c => c.field === sortBy) ? [] : defaultCriteria;

    return [
        {
            field: sortBy,
            ascending: sortDirection === SORT.ASC,
        },
        ...additionalCriteria,
    ];
}

function castFiltersConfig(jsonValue: any) {
    return (jsonValue as UserConfigFilter[])
        .sort((a, b) => compareStrings(a.name, b.name))
        .filter(f => f.name !== "enhancedCLO"); // Temporary Enhanced CLO hiding
}

function castColumnsConfig(jsonValue: any) {
    return (jsonValue as UserConfigColumn[])
        .filter(c => c.available)
        .sort((a, b) => a.order - b.order);
}

function parseConfigValue(userConfig: ImUserConfig) {
    try {
        const json = JSON.parse(userConfig.value);

        switch (userConfig.type) {
            case UserConfigType.imFilter:
            case UserConfigType.apFilter:
                return castFiltersConfig(json);
            case UserConfigType.imColumnsClassTab:
            case UserConfigType.imColumnsDealTab:
                return castColumnsConfig(json);
            case UserConfigType.imAlert:
            case UserConfigType.transactionAlert:
            case UserConfigType.weeklyStatsEmail:
            case UserConfigType.newPublishedTransactionAlert:
            case UserConfigType.summaryDailyAlerts:
            case UserConfigType.arrangerPipelineAlert:
            case UserConfigType.cloManagerAnalyticsAlert:
            case UserConfigType.cloManagerIssuanceMonitorAlert:
            case UserConfigType.cloManagerArrangerPipelineAlert:
            case UserConfigType.cloManagerBwicAlert:
            case UserConfigType.cloManagerDealerInventoryAlert:
            case UserConfigType.bankAnalyticsAlert:
            case UserConfigType.bankDealerInventoryAlert:
            case UserConfigType.bankIssuanceMonitorAlert:
            case UserConfigType.bankArrangerPipelineAlert:
                return json;
            default:
                return [];
        }
    } catch {
        return [];
    }
}

export function mapConfig(response?: ImUserConfig[]) {
    if (!response || !response.length) {
        return [];
    }

    return response.map(config => ({
        type: config.type,
        value: parseConfigValue(config),
    }));
}

export function getDocumentCountBySession(session: AnalyticsSession<string, DownloadedDocumentAccessType>) {
    const sumByPredicate = (session: ViewedEntity<DownloadedDocumentAccessType>) => session.documents.length;

    const allDocuments = sumBy(session.documents.DownloadAllDocuments, sumByPredicate);
    const oneDocument = sumBy(session.documents.DownloadOneDocument, sumByPredicate);

    return {
        dealDocumentsViews: allDocuments + oneDocument,
        managerPresentationViews: sumBy(session.documents.DownloadManagerPresentationFile, sumByPredicate),
        targetPortfolioViews: sumBy(session.documents.ExportTargetPortfolio, sumByPredicate),
        intexFileViews: sumBy(session.documents.DownloadIntexFile, sumByPredicate),
        disclosureFileViews: sumBy(session.documents.DownloadDisclosureFile, sumByPredicate),
    };
}

export function getDocumentCountByViewHistory(transactionViewHistory: TransactionViewHistoryClientsActivity) {
    const documentDownloads = mapValues(keyBy(transactionViewHistory.documentDownloads, 'accessType'), 'numberOfAccess');

    const allDocuments = get(documentDownloads, DownloadedDocumentAccessType.DownloadAllDocuments, 0);
    const oneDocument = get(documentDownloads, DownloadedDocumentAccessType.DownloadOneDocument, 0);

    return {
        dealDocumentsViews: allDocuments + oneDocument,
        managerPresentationViews: get(documentDownloads, DownloadedDocumentAccessType.DownloadManagerPresentationFile, 0),
        targetPortfolioViews: get(documentDownloads, TransactionAccessType.ExportTargetPortfolio, 0),
        intexFileViews: get(documentDownloads, DownloadedDocumentAccessType.DownloadIntexFile, 0),
        disclosureFileViews: get(documentDownloads, DownloadedDocumentAccessType.DownloadDisclosureFile, 0),
    };
}

export function getAlertValues(values: (CompanyAlertsValue | SecondaryStatsAlertsValue)[]) {
    return new Map(values.map(v => [v.companyReferenceName, v]));
}

export function withTurnedOnAlerts(values: (CompanyAlertsValue | SecondaryStatsAlertsValue)[], referenceName?: string) {
    const alertOptions = values.filter(
        c => c.companyReferenceName === referenceName,
    );

    return alertOptions.some(({ alertOption }) => alertOption !== AlertOption.Never);
}

export function combineBanks(amrData: Company[], bwicData: BwicBank[], userCompanyReferenceName?: string) {
    const amrBank: Bank[] = amrData.map(a => ({
        referenceName: a.referenceName,
        legalName: a.legalName,
        isUserCompany: a.referenceName === userCompanyReferenceName,
        hasActiveTransactions: a.hasActiveTransactions,
        sendsPxTalk: false,
        existsOnDealerInventory: false,
        code: a.code || '',
    }));

    const bwicBank: Bank[] = bwicData.map(b => ({
        referenceName: b.referenceName,
        legalName: b.legalName,
        isUserCompany: b.referenceName === userCompanyReferenceName,
        hasActiveTransactions: false,
        sendsPxTalk: b.isSecondary,
        existsOnDealerInventory: b.isInventory,
        code: b.code,
    }));

    const banks = [...amrBank, ...bwicBank].reduce((accumulator: Map<string, Bank>, bank: Bank) => {
        const existingBank = accumulator.get(bank.referenceName);

        const newBank = existingBank
            ? {
                ...existingBank,
                code: existingBank.code || bank.code,
                hasActiveTransactions: existingBank.hasActiveTransactions || bank.hasActiveTransactions,
                sendsPxTalk: existingBank.sendsPxTalk || bank.sendsPxTalk,
                existsOnDealerInventory: existingBank.existsOnDealerInventory || bank.existsOnDealerInventory,
            } : bank;

        accumulator.set(bank.referenceName, newBank);

        return accumulator;
    }, new Map());

    return Array.from(banks.values());
}

export function getDealDocLastDate(document: AmrFile | Document) {
    if (document.executionDate) {
        return document.executionDate;
    }

    return dateTimeUtils.resetTimeZone(document.uploadTime) || undefined;
}
