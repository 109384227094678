export interface Document {
    id: string;
    name: string;
    uploadTime: Date;
    referenceName?: string;
    executionDate?: string | Date;
}

export enum DocumentStoreType {
    Default = "Default",
    Settlement = "Settlement",
    Disclosure = "Disclosure"
}
