import moment from 'moment';
import { stringUtils } from './string.utils';
import { constants } from '../constants/constants';
import { numericUtils } from './numeric.utils';
import clone from 'lodash/clone';

export default class dateTimeUtils {
    static currentUtcDate() {
        return moment().format(constants.dateTimeFormatUtc);
    }

    static timestampToUtcString(timestamp) {
        return moment(timestamp, constants.timeFormat).format(constants.dateTimeFormatUtc);
    }

    static timestampFromUtcString(utcString, timeZone) {
        if (timeZone && timeZone.abbreviation) {
            const time = moment.utc(utcString).format(constants.timeFormat);
            return `${time} ${timeZone.abbreviation}`;
        }
        return moment.utc(utcString).format(constants.timeFormat);
    }

    static utcToLocalString(utcString, customDateTimeFormat) {
        const dateTimeFormat = customDateTimeFormat || constants.dateTimeFormat;
        return moment.utc(utcString).local().format(dateTimeFormat);
    }

    static duration(utcString) {
        if (!utcString) {
            return '';
        }

        const now = moment.utc();
        const date = moment.utc(utcString);
        const minutes = now.diff(date, 'minutes');

        if (minutes === 0) {
            return 'just now';
        } else if (minutes === 1) {
            return '1 min ago';
        } else if (minutes < 60) {
            return `${minutes} mins ago`;
        } else {
            const days = now.startOf('day').diff(moment.utc(utcString).startOf('day'), 'days');
            if (days === 0) {
                return `today at ${moment.utc(utcString).local().format(constants.timeFormat)}`;
            } else if (days === 1) {
                return `yesterday at ${moment.utc(utcString).local().format(constants.timeFormat)}`;
            }
        }

        return dateTimeUtils.utcToLocalString(utcString);
    }

    static durationDays(date) {
        const now = moment();
        const currentDate = moment(date);
        const days = now.startOf('day').diff(currentDate.startOf('day'), 'days');
        if (days === 0) {
            return 'Today'
        }
        if (days === 1) {
            return 'Yesterday'
        }
        if (days <= 6) {
            return `${days} Days Ago`
        }
        return currentDate.format(constants.dateFormat);
    }

    static timeLeftToDateFrom(utcString, fromDateString) {
        if (!utcString || !fromDateString) {
            return 0;
        }

        const date = moment.utc(utcString);
        const fromDate = moment.utc(fromDateString);

        const days = date.diff(fromDate, 'days');
        const weeks = date.diff(fromDate, 'weeks');
        const month = date.diff(fromDate, 'month');
        const years = date.diff(fromDate, 'years');

        if (days <= 0) {
            return 0;
        } else if (days === 1) {
            return `${days} day`
        } else if (days < 7) {
            return `${days} days`
        } else if (days < 61) {
            return `${weeks} w`
        } else if (days < 366) {
            return `${month} mo`
        } else {
            return `${month % 12 ? (month / 12).toFixed(1) : years} yrs`;
        }
    }

    static yrsLeftToDateFrom(utcString, fromDateString, showSuffix = true) {
        if (!utcString || !fromDateString) {
            return 0;
        }

        const date = moment.utc(utcString);
        const fromDate = moment.utc(fromDateString);

        const days = date.diff(fromDate, 'days');

        return days > 0 ? `${(days / 365).toFixed(2)} ${showSuffix ? 'yrs' : ''}` : 0;
    }

    static parseDate(date, format) {
        if (date && format) {
            const dateMoment = moment(date, format, true).startOf("day");
            return dateMoment.isValid() ? dateMoment.toDate() : undefined;
        }
        return date;
    }

    // dateStamp: MM/YY
    static parseMonthAndYearStamp(dateStamp) {
        if (stringUtils.isNullOrWhiteSpace(dateStamp)) {
            return {};
        }

        function checkRange(number, min, max) {
            return number >= min && number <= max;
        }

        const [monthRaw, yearRaw] = String(dateStamp).split('/');

        const isMonthValid =
            !stringUtils.isNullOrWhiteSpace(monthRaw) &&
            numericUtils.isNumber(monthRaw.trim()) &&
            checkRange(+(monthRaw.trim()), 1, 12);

        const isYearValid =
            !stringUtils.isNullOrWhiteSpace(yearRaw) &&
            numericUtils.isNumber(yearRaw.trim()) &&
            checkRange(+(yearRaw.trim()), 0, 99);


        const month = numericUtils.numberOrDefault(monthRaw, NaN);
        const year = numericUtils.numberOrDefault(yearRaw, NaN);

        return { month, year, isMonthValid, isYearValid };
    }

    static isToday(date) {
        return moment.parseZone(date).isSame(moment(), 'day');
    }

    static changeDateTimeZone(date, targetTimeZone, keepTime = true) {
        return keepTime
            ? moment(date).utcOffset(0, true).tz(targetTimeZone, true)
            : moment(date).tz(targetTimeZone);
    }

    static setTime(date, time) {
        const momentDate = moment(time)
        return moment(date)
            .set({
                hour: momentDate.format('H'),
                minute: momentDate.format('m'),
                second: momentDate.format('s'),
                millisecond: 0
            })
    }

    static timeSpan(hours = 0, minutes = 0, seconds = 0) {
        const padZero = value => String(value).padStart(2, '0');

        const timeInSec = seconds + (minutes * 60) + (hours * 3600)

        const hh = Math.floor(timeInSec / 3600);
        const mm = Math.floor(timeInSec % 3600 / 60);
        const ss = Math.floor(timeInSec % 3600 % 60);

        return `${padZero(hh)}:${padZero(mm)}:${padZero(ss)}`;
    }

    static parseTimeSpan(timeSpan) {
        const [hours, minutes, seconds] = (timeSpan == null ? '' : String(timeSpan)).split(":");

        return {
            hours: numericUtils.numberOrDefault(hours),
            minutes: numericUtils.numberOrDefault(minutes),
            totalMinutes: numericUtils.numberOrDefault(Number(hours) * 60 + Number(minutes)),
            seconds: numericUtils.numberOrDefault(seconds),
            raw: { hours, minutes, seconds }
        };
    }

    static getMaxDate(dates) {
        return dates.length ? moment.max(dates.map(d => moment(d))).toDate() : undefined;
    }

    static getLastBusinessDay() {
        let workday = moment();
        let day = workday.day();
        let diff = 1;
        if (day === 0 || day === 1){ 
            diff = day + 2;
        }
        return workday.subtract(diff, 'days');
    }

    static isDayBefore(d1, d2) {
        const day1 = clone(d1).setHours(0, 0, 0, 0);
        const day2 = clone(d2).setHours(0, 0, 0, 0);
        return day1 < day2;
    }

    static isDayAfter(d1, d2) {
        const day1 = clone(d1).setHours(0, 0, 0, 0);
        const day2 = clone(d2).setHours(0, 0, 0, 0);
        return day1 > day2;
    }

    static resetTimeZone(date) {
        if (!date) {
            return null;
        }

        const parsedDate = moment.parseZone(date);
        const localDate = moment({
            year: parsedDate.year(),
            month: parsedDate.month(),
            day: parsedDate.date(),
            hour: parsedDate.hour(),
            minute: parsedDate.minute(),
            second: parsedDate.second(),
            millisecond: parsedDate.millisecond(),
        });
        return localDate.toDate();
    }
}
