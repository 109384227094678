import { UserSlim } from "../account/UserSlim";
import { CompanySlim } from "../company/CompanySlim";

export interface AccessToken {
    id: number;
    apis: AccessTokenApi[];
    company: CompanySlim;
    createdBy: UserSlim;
    creationDate: Date;
    expirationDate: Date;
    token: string;
    revokeDate?: Date;
    status: ApiTokenStatus;
}

export enum ApiTokenStatus {
    Active = 1,
    Expired = 2,
    Revoked = 3
}

export const AccessTokenStatusLabels = {
    [ApiTokenStatus.Active]: 'Active',
    [ApiTokenStatus.Expired]: 'Expired',
    [ApiTokenStatus.Revoked]: 'Revoked',
}

export enum AccessTokenApi {
    BlotterApiReader = 'BlotterApiReader',
    BwicMonitorApiReader = 'BwicMonitorApiReader',
    IssuanceMonitorApi = 'issuanceMonitor',
    IssuanceMonitorApiReader = 'IssuanceMonitorApiReader',
    IssuanceMonitorApiHistoryReader = 'IssuanceMonitorApiHistoryReader',
    PortfolioApiReaderWriter = 'PortfolioApiReaderWriter', // TO DO: Remove
    PortfolioApiReader = 'PortfolioApiReader',
    PortfolioApiWriter = 'PortfolioApiWriter',
    BwicMonitorApiHistoryReader = 'BwicMonitorApiHistoryReader',
    AllBwicMonitorApi = 'AllBwicMonitorApi', // TO DO: Remove
    EvalApiReader = 'EvalApiReader',
    DashboardApiReader = 'DashboardApiReader',
}

export const AccessTokenApiLabels = {
    [AccessTokenApi.BlotterApiReader]: 'Blotter API',
    [AccessTokenApi.BwicMonitorApiReader]: 'Actual Data',
    [AccessTokenApi.IssuanceMonitorApiReader]: 'Upcoming',
    [AccessTokenApi.IssuanceMonitorApiHistoryReader]: 'Historical',
    [AccessTokenApi.PortfolioApiReaderWriter]: 'Portfolio API',
    [AccessTokenApi.PortfolioApiReader]: 'Get a list of user`s portfolios',
    [AccessTokenApi.PortfolioApiWriter]: 'Create/Update portfolio',
    [AccessTokenApi.BwicMonitorApiHistoryReader]: 'Historical Data',
    [AccessTokenApi.AllBwicMonitorApi]: 'BWIC Monitor API',
    [AccessTokenApi.IssuanceMonitorApi]: 'Issuance Monitor API',
    [AccessTokenApi.EvalApiReader]: 'Eval API',
    [AccessTokenApi.DashboardApiReader]: 'Dashboard API',
}
