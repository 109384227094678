import moment, { Moment } from 'moment';
import { DownloadedDocument, EntityViews, SessionAccess } from '../types/amr-pipeline/models/AnalyticsSession';
import { flatten, groupBy, sumBy, uniqBy, values } from 'lodash';
import { TreeSelectOption } from '../components/controls/TreeSelect';
import { DownloadedDocumentAccessType } from '../types/amr-pipeline/enums/DownloadedDocumentAccessType';

export function withDate<T extends { date: Moment }>(data: any[]): T[] {
    return data.map(({ day, month, year, ...rest }) => ({
        ...rest,
        date: moment([year, month - 1, day]),
    } as T));
}

export function transformToTreeSelect<T extends string>(clientsActivity: SessionAccess<T>[], accessTypes: T[]) {
    const viewedTabs = clientsActivity.filter(x => accessTypes.includes(x.accessType));
    const groupedByUser = groupBy(viewedTabs, x => x.userEmail);

    const aggregatedByAccess = Object.values(groupedByUser).reduce((acc: SessionAccess<T>[], rows: SessionAccess<T>[]) => {
        const [firstRow] = rows;

        if (!firstRow) {
            return acc;
        }

        const numberOfAccess = sumBy(rows, row => row.numberOfAccess);

        const { accessType, ...rest } = firstRow;

        return [
            ...acc,
            { ...rest, numberOfAccess } as SessionAccess<T>,
        ];
    }, []);

    return aggregatedByAccess.reduce((acc: TreeSelectOption<string>[], clientActivity: SessionAccess<T>) => {
        const existingItem = acc.find(x => x.value === clientActivity.companyReferenceName);

        if (existingItem) {
            return [
                ...acc.filter(x => x.value !== clientActivity.companyReferenceName),
                {
                    ...existingItem,
                    children: [
                        ...(existingItem.children || []),
                        {
                            text: clientActivity.userFullName,
                            value: clientActivity.userEmail,
                            meta: {
                                views: clientActivity.numberOfAccess,
                            },
                        },
                    ],
                },
            ];
        }

        return [
            ...acc,
            {
                text: clientActivity.companyLegalName,
                value: clientActivity.companyReferenceName,
                children: [
                    {
                        text: clientActivity.userFullName,
                        value: clientActivity.userEmail,
                        meta: {
                            views: clientActivity.numberOfAccess,
                        },
                    },
                ],
            },
        ];
    }, []);
}

export function groupDocsWithAccessType(documents: EntityViews<DownloadedDocumentAccessType>) {
    return flatten(
        flatten(values(documents)).map(({ documents, accessType }) =>
            documents.map((d) => ({ ...d, accessType }))
        )
    );
}

export function getUniqueDocsWithDownloadsCount(documents: DownloadedDocument[]) {
    const uniqueDocuments = uniqBy(documents, 'referenceName');

    const documentCountMap = documents.reduce((acc, doc) => {
        acc[doc.referenceName] = (acc[doc.referenceName] || 0) + 1;
        return acc;
    }, {} as Record<string, number>);

    return uniqueDocuments.map(doc => ({
        ...doc,
        downloadCount: documentCountMap[doc.referenceName],
    }));
}
